import React, {
    createContext,
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react'

import * as yup from 'yup'
import PropTypes from 'prop-types'

import { getCardType, getValueIntl, utf8_to_b64 } from '../utils'
import {
    createPublicKey,
    encryptV2,
    generateAESPairs,
    pack,
    setPublicKey
} from '../encryption'
import { checkout } from '../api/checkout'
import { CONSTANTS } from '../config/constants'
import { CONFIG } from '../config'
import { af_pa_setup, af_pa_enrollment, af_pa_validate } from '../api/merchant'

const emailRegex = new RegExp(
    /^\w+[\w.+-]*?@[a-zA-Z]+\w*?(\.[a-zA-Z]{2,}|(\.[a-zA-Z]{2,})\.[a-zA-Z]{2,})$/
)

const cardnumberRegex1 = new RegExp(
    /^(?:4\d{12}(?:\d{3})?|[25][1-7]\d{14}|6(?:011|5\d\d)\d{12}|3[47]\d{13}|3(?:0[0-5]|[68]\d)\d{11}|50228600\d{8}|(?:2131|1800|35\d{3})\d{11})$/
)

const cardnumberRegex2 = new RegExp(
    /^(?:4\d{12}(?:\d{3})?|[25][1-7]\d{14}|6(?:011|5\d\d)\d{12}|3[47]\d{13}|3(?:0[0-5]|[68]\d)\d{11}|23500700\d{8}|(?:2131|1800|35\d{3})\d{11})$/
)
export const CheckoutFormContext = createContext({
    state: {
        issuer: '',
        focused: null,
        orderRef: null
    },
    cardDataState: {
        number: '',
        cardholder: '',
        month: '',
        year: '',
        cvc: '',
        numberOfPayments: '',
        tokenize: false,
        paymentMethod: '',
        terms: false,
        planValue: 0
    },
    handleInputFocus: undefined,
    handleInputChange: undefined,
    schemaCheckoutCardForm: null,

    clientInfoChargeLinkState: {
        clientName: '',
        email: '',
        phoneNumber: '',
        country: '',
        province: '',
        canton: '',
        district: '',
        address: '',

        state: '',
        city: '',
        zipcode: ''
    },
    handleInputFocusClientInfo: undefined,
    handleInputChangeClientInfo: undefined,
    schemaClientInformationChargeLinkForm: null,

    zunifyState: {
        phoneNumber: '',
        pin: '',
        paymentMethod: ''
    },
    handleInputFocusZunify: undefined,
    handleInputChangeZunify: undefined,
    schemaZunifyCheckoutForm: null,

    handleOrderChange: undefined,

    showNumberOfPayments: undefined,

    payOrder: undefined,
    payOrderZunify: undefined,
    processingOrder: {
        status: ''
    },
    isInvoice: false
})

export const CheckoutFormContextProvider = (props) => {
    const url = window.location.href
    const isInvoice = url.indexOf('/invoice/') !== -1
    const credixRegex = '(.*?)credix(.*?)'

    const [cardDataState, setCardDataState] = useState({
        number: '',
        cardholder: '',
        month: '',
        year: '',
        cvc: '',
        numberOfPayments: '',
        tokenize: false,
        paymentMethod: '',
        terms: false,
        planValue: 0
    })

    const [clientInfoChargeLinkState, setClientInfoChargeLinkState] = useState({
        clientName: '',
        email: '',
        phoneNumber: '',
        country: 'CR',
        province: 'San José',
        canton: 'San José',
        district: 'Carmen (10101)',
        address: '',

        state: '',
        city: '',
        zipcode: ''
    })

    const [zunifyState, setZunifyState] = useState({
        phoneNumber: '',
        pin: '',
        paymentMethod: ''
    })

    const [state, setState] = useState({
        issuer: '',
        focused: '',
        clientInfoFocused: '',
        zunifyFocused: '',
        orderRef: props.initialOrder || null
    })

    const [processingOrder, setProcessingOrder] = useState({
        status: ''
    })

    const { number } = cardDataState

    const af_pa_setup_3DS = useCallback(
        async (number, month, year) => {
            const cardData = {
                card: {
                    expirationDate: {
                        month: month,
                        year: year
                    },
                    cardNumber: number.replaceAll(' ', '')
                }
            }

            setPublicKey(createPublicKey(state.orderRef?.privateKey))
            const data = await encryptV2(cardData, state.orderRef?.session)

            return await af_pa_setup(data, state.orderRef?.token)
        },
        [state]
    )

    const af_pa_enrollment_3DS = useCallback(
        async (
            number,
            month,
            year,
            cardholder,
            cvc,
            quota,
            dataSetup3DS = {}
        ) => {
            const cardData = {
                card: {
                    expirationDate: {
                        month: month,
                        year: year
                    },
                    cardNumber: number.replaceAll(' ', ''),
                    cardHolder: cardholder,
                    cvc
                },
                quota
            }

            setPublicKey(createPublicKey(state.orderRef?.privateKey))
            const data = await encryptV2(cardData, state.orderRef?.session)

            return await af_pa_enrollment(
                data,
                state.orderRef?.token,
                dataSetup3DS.referenceId,
                dataSetup3DS?.transaction?.id
            )
        },
        [state]
    )

    const af_pa_validate_3DS = useCallback(
        async (
            number,
            month,
            year,
            cardholder,
            cvc,
            quota,
            dataSetup3DS = {},
            response3DSPAEnrollment = {}
        ) => {
            const cardData = {
                card: {
                    expirationDate: {
                        month: month,
                        year: year
                    },
                    cardNumber: number.replaceAll(' ', ''),
                    cardHolder: cardholder,
                    cvc: cvc
                },
                quota
            }

            setPublicKey(createPublicKey(state.orderRef?.privateKey))
            const data = await encryptV2(cardData, state.orderRef?.session)

            return await af_pa_validate(
                data,
                state.orderRef?.token,
                dataSetup3DS?.transaction?.id,
                response3DSPAEnrollment?.authenticationTransactionId
            )
        },
        [state]
    )

    useEffect(() => {
        if (number && number.length > 0) {
            const issuer = getCardType(number.replaceAll(' ', ''))
            setState({ ...state, issuer })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number])

    const handleInputFocus = useCallback(
        ({ target }) => {
            setState({ ...state, focused: target.name })
        },
        [state]
    )

    const handleInputFocusClientInfo = useCallback(
        ({ target }) => {
            setState({ ...state, clientInfoFocused: target.name })
        },
        [state]
    )

    const handleInputChange = useCallback(
        ({ target }) => {
            setCardDataState({ ...cardDataState, [target.name]: target.value })
        },
        [cardDataState]
    )

    const handleInputFocusZunify = useCallback(
        ({ target }) => {
            setState({ ...state, zunifyFocused: target.name })
        },
        [state]
    )

    const handleInputChangeZunify = useCallback(
        ({ target }) => {
            setZunifyState({ ...zunifyState, [target.name]: target.value })
        },
        [zunifyState]
    )

    const handleOrderChange = useCallback(
        (order) => {
            setState({ ...state, orderRef: order })
        },
        [state]
    )

    const getClientIp = async () => {
        try {
            let clientIp = null

            if (typeof window.gpDataCollector !== 'undefined') {
                const { getClientInfo } = window.gpDataCollector
                const { ip } = await getClientInfo()
                clientIp = ip
            }
            return clientIp
        } catch (error) {
            console.error('Error getting ip data collector')
            return null
        }
    }

    const callback = useCallback(
        (data) => {
            const callbackUrl =
                state?.orderRef?.callback || state?.orderRef?.merchant?.callback
            if (callbackUrl) {
                setTimeout(() => {
                    window.location =
                        callbackUrl +
                        '/' +
                        window.btoa(
                            window.decodeURIComponent(
                                encodeURIComponent(JSON.stringify(data))
                            )
                        )
                }, 2000)
            }
        },
        [state?.orderRef?.callback, state?.orderRef?.merchant?.callback]
    )

    const payOrderZunify = useCallback(
        async ({ obj }) => {
            try {
                setProcessingOrder({
                    ...processingOrder,
                    status: CONSTANTS.MESSAGES.PROCESSING_ZUNIFY_ORDER
                })

                let ipClient = await getClientIp()

                if (ipClient) {
                    obj.shopper_ip = ipClient
                }

                setPublicKey(createPublicKey(state.orderRef?.privateKey))
                const pair = generateAESPairs()
                const data = pack(obj, state.orderRef?.session, pair)
                const response = await checkout(
                    data,
                    state.orderRef?.token,
                    CONFIG.ZUNIFY_ENDPOINT,
                    true
                )

                if (response.code === 200) {
                    setProcessingOrder({
                        ...processingOrder,
                        status: CONSTANTS.MESSAGES.ORDER_ZUNIFY_DONE
                    })
                } else {
                    setProcessingOrder({
                        ...processingOrder,
                        status: CONSTANTS.MESSAGES.ORDER_ZUNIFY_FAIL
                    })
                }

                if (!isInvoice) {
                    callback(response)
                }
            } catch (error) {
                setProcessingOrder({
                    ...processingOrder,
                    status: CONSTANTS.MESSAGES.ORDER_ZUNIFY_FAIL
                })

                if (!isInvoice) {
                    callback(error)
                }
            }
        },
        [
            state.orderRef?.privateKey,
            state.orderRef?.session,
            state.orderRef?.token,
            processingOrder,
            callback,
            isInvoice
        ]
    )

    const setProccesingStatus = useCallback(
        (status) =>
            setProcessingOrder({
                ...processingOrder,
                status
            }),
        [processingOrder]
    )

    const setErrorPayOrder = (error) => {
        // Controlled error
        if (error?.status) {
            return error
        } else if (error?.response?.data) {
            return {
                ...error.response.data,
                errors: ['Internal error'],
                status: 400
            }
        } else {
            return {
                status: 400,
                errors: [error?.message || 'Error']
            }
        }
    }

    const setInvoiceInfo = useCallback(
        (response) => ({
            authorization: response.authorization,
            orderId: response.orderId.replace('invoice-', ''),
            logo: state.orderRef?.merchant?.logo,
            merchant: state.orderRef?.merchant?.name || '',
            amount: state.orderRef?.amount || '',
            currency: state.orderRef?.currency || ''
        }),
        [
            state.orderRef?.merchant?.logo,
            state.orderRef?.merchant?.name,
            state.orderRef?.amount,
            state.orderRef?.currency
        ]
    )

    const payOrder = useCallback(
        async ({ obj, navigate, version = 1 }) => {
            setProccesingStatus(CONSTANTS.MESSAGES.PROCESSING_ORDER)

            let ipClient = await getClientIp()

            if (ipClient) {
                obj.shopper_ip = ipClient
            }
            setPublicKey(createPublicKey(state.orderRef?.privateKey))
            const pair = generateAESPairs()
            const data =
                String(version) === '1'
                    ? pack(obj, state.orderRef?.session, pair)
                    : await encryptV2(obj, state.orderRef?.session)

            let response = null
            try {
                response = await checkout(
                    data,
                    state.orderRef?.token,
                    String(version) === '1'
                        ? CONFIG.CHECKOUT_ENDPOINT
                        : CONFIG.ORCHESTRATOR_ENDPOINTV2,
                    false
                )
            } catch (error) {
                response = setErrorPayOrder(error)
                setProcessingOrder(CONSTANTS.MESSAGES.ORDER_FAIL)
            }

            try {
                if (response && response?.status === 200) {
                    setProccesingStatus(CONSTANTS.MESSAGES.ORDER_DONE)

                    if (isInvoice) {
                        const invoiceData = setInvoiceInfo(response)
                        setTimeout(() => {
                            navigate(
                                `/payment-invoice/${utf8_to_b64(
                                    JSON.stringify(invoiceData)
                                )}`
                            )
                        }, 2000)
                    }
                }

                if (response?.status && response.status !== 200) {
                    setProccesingStatus(CONSTANTS.MESSAGES.ORDER_FAIL)
                }

                if (!isInvoice && response?.status) {
                    callback(response)
                    return
                }
            } catch (error) {
                setProccesingStatus(CONSTANTS.MESSAGES.ORDER_FAIL)
            }
        },
        [
            state.orderRef?.privateKey,
            state.orderRef?.session,
            state.orderRef?.token,
            callback,
            isInvoice,
            setProccesingStatus,
            setInvoiceInfo
        ]
    )

    const handleInputChangeClientInfo = useCallback(
        ({ target }) => {
            setClientInfoChargeLinkState({
                ...clientInfoChargeLinkState,
                [target.name]: target.value
            })
        },
        [clientInfoChargeLinkState]
    )

    const showNumberOfPayments = useCallback(() => {
        let _showNumberOfPayments = false
        const isArrayTerminals = Array.isArray(state?.orderRef?.terminal)
        let isCredix = false

        if (
            (isArrayTerminals &&
                state?.orderRef?.terminal.some((e) =>
                    new RegExp(credixRegex, 'i').test(e)
                )) ||
            new RegExp(credixRegex, 'i').test(state?.orderRef?.terminal)
        ) {
            isCredix = true
        }

        if (state?.orderRef?.numberOfPayments && isCredix) {
            _showNumberOfPayments = true
        }

        return _showNumberOfPayments
    }, [state?.orderRef?.numberOfPayments, state?.orderRef?.terminal])

    const yupState =
        clientInfoChargeLinkState.country === 'US'
            ? yup
                  .string()
                  .required(
                      getValueIntl(
                          'CHARGE_LINK_CLIENT_STATE_REQUIRED',
                          'State is required'
                      )
                  )
            : yup.string()

    const yupCity =
        clientInfoChargeLinkState.country === 'US'
            ? yup
                  .string()
                  .required(
                      getValueIntl(
                          'CHARGE_LINK_CLIENT_CITY_REQUIRED',
                          'City is required'
                      )
                  )
            : yup.string()

    const yupZipcode =
        clientInfoChargeLinkState.country === 'US'
            ? yup
                  .string()
                  .required(
                      getValueIntl(
                          'CHARGE_LINK_CLIENT_ZIPCODE',
                          'Zip code is required'
                      )
                  )
            : yup.string()

    const schemaClientInformationChargeLinkForm = yup.object().shape({
        clientName: yup
            .string()
            .required(
                getValueIntl(
                    'CHARGE_LINK_CLIENT_NAME_REQUIRED',
                    'Client name is required'
                )
            ),
        email: yup
            .string()
            .required(
                getValueIntl(
                    'CHARGE_LINK_CLIENT_EMAIL_REQUIRED',
                    'Email is required'
                )
            )
            .matches(emailRegex, {
                message: getValueIntl(
                    'CHARGE_LINK_CLIENT_EMAIL_INVALID',
                    'Invalid Email'
                )
            }),
        phoneNumber: yup
            .string()
            .required(
                getValueIntl(
                    'CHARGE_LINK_CLIENT_PHONE_REQUIRED',
                    'Phone number is required'
                )
            ),
        country: yup
            .string()
            .required(
                getValueIntl(
                    'CHARGE_LINK_CLIENT_COUNTRY_REQUIRED',
                    'Country is required'
                )
            ),
        province: yup.string().when('country', {
            is: 'Costa Rica',
            then: yup
                .string()
                .required(
                    getValueIntl(
                        'CHARGE_LINK_CLIENT_PROVINCE_REQUIRED',
                        'Province is required'
                    )
                )
        }),
        canton: yup.string().when('country', {
            is: 'Costa Rica',
            then: yup
                .string()
                .required(
                    getValueIntl(
                        'CHARGE_LINK_CLIENT_CANTON_REQUIRED',
                        'Canton is required'
                    )
                )
        }),
        district: yup.string().when('country', {
            is: 'Costa Rica',
            then: yup
                .string()
                .required(
                    getValueIntl(
                        'CHARGE_LINK_CLIENT_DISTRICT_REQUIRED',
                        'District is required'
                    )
                )
        }),
        address: yup
            .string()
            .required(
                getValueIntl(
                    'CHARGE_LINK_CLIENT_ADDRESS_REQUIRED',
                    'Address is required'
                )
            ),
        state: yupState,
        city: yupCity,
        zipcode: yupZipcode
    })

    const yupPaymentMethod = isInvoice
        ? yup
              .string()
              .required(
                  getValueIntl(
                      'SELECT_TERRMINAL_PAYMENT_METHOD_REQUIRED',
                      'Payment method is required'
                  )
              )
        : yup.string()

    const yupNumberOfPayments = showNumberOfPayments()
        ? yup
              .number()
              .required(
                  getValueIntl(
                      'NUMBER_OF_PAYMENTS_REQUIRED',
                      'Number of payments is required'
                  )
              )
        : yup.string()

    const termsAndConditions =
        state.orderRef?.merchant?.terms_n_conditions !== undefined &&
        state.orderRef?.merchant?.terms_n_conditions !== ''
            ? yup.bool().required()
            : yup.bool()

    const schemaCheckoutCardForm = yup.object().shape({
        number: yup
            .string()
            .required(
                getValueIntl('CARD_NUMBER_REQUIRED', 'Card number is requerid')
            )
            .trim()
            .matches(/[\d| ]{15,22}/, {
                message: getValueIntl(
                    'CARD_NUMBER_INVALID',
                    'Invalid card number'
                )
            })
            .matches(cardnumberRegex1, {
                message: getValueIntl(
                    'CARD_NUMBER_INVALID',
                    'Invalid card number'
                )
            })
            .matches(cardnumberRegex2, {
                message: getValueIntl(
                    'CARD_NUMBER_INVALID',
                    'Invalid card number'
                )
            })
            .transform((currentValue) => currentValue.replaceAll(' ', '')),
        cardholder: yup
            .string()
            .required(
                getValueIntl(
                    'CARD_CARDHOLDER_REQUIRED',
                    'Card holder is required'
                )
            )
            .min(
                5,
                getValueIntl(
                    'CARD_CARDHOLDER_MIN_LENGTH',
                    'Carholder must be at least 5 characters'
                )
            )
            .max(
                50,
                getValueIntl(
                    'CARD_CARDHOLDER_MAX_LENGTH',
                    'Cardholder must be at most 50 characters'
                )
            ),
        month: yup
            .string()
            .required(getValueIntl('CARD_MONTH_REQUIRED', 'Month is required'))
            .min(
                2,
                getValueIntl(
                    'CARD_MONTH_MIN_LENGTH',
                    'Month must be at least 2 characters'
                )
            )
            .max(
                2,
                getValueIntl(
                    'CARD_MONTH_MAX_LENGTH',
                    'Month must be at most 2 characters'
                )
            )
            .matches(/^(?:01|02|03|04|05|06|07|08|09|10|11|12)$/, {
                message: getValueIntl('CARD_MONTH_INVALID', 'Invalid month')
            }),
        year: yup
            .string()
            .required(getValueIntl('CARD_YEAR_REQUIRED', 'Year is required'))
            .min(
                2,
                getValueIntl(
                    'CARD_YEAR_MIN_LENGTH',
                    'Year must be at least 2 characters'
                )
            )
            .max(
                2,
                getValueIntl(
                    'CARD_YEAR_MAX_LENGTH',
                    'Year must be at most 2 characters'
                )
            )
            .matches(/^\d{2}$/, {
                message: getValueIntl('CARD_YEAR_INVALID', 'Invalid month')
            }),
        cvc: yup
            .string()
            .required(getValueIntl('CARD_CVC_REQUIRED', 'CVC is required'))
            .max(
                4,
                getValueIntl(
                    'CARD_CVC_MAX_LENGTH',
                    'CVC must be at least 3 characters'
                )
            )
            .min(
                3,
                getValueIntl(
                    'CARD_CVC_MIN_LENGTH',
                    'CVC must be at most 4 characters'
                )
            ),
        tokenize: yup.bool(),
        numberOfPayments: yupNumberOfPayments,
        paymentMethod: yupPaymentMethod,
        terms: termsAndConditions
    })

    const schemaZunifyCheckoutForm = yup.object({
        phoneNumber: yup
            .string()
            .required(
                getValueIntl(
                    'ZUNIFY_PHONE_NUMBER_REQUIRED',
                    'Phone number is required'
                )
            )
            .min(
                8,
                getValueIntl(
                    'ZUNIFY_PHONE_NUMBER_MIN_LENGTH',
                    'Phone number must be at least 8 characters'
                )
            )
            .max(
                8,
                getValueIntl(
                    'ZUNIFY_PHONE_NUMBER_MAX_LENGTH',
                    'Phone number must be at most 8 characters'
                )
            ),
        pin: yup
            .string()
            .min(
                4,
                getValueIntl(
                    'ZUNIFY_PIN_MIN_LENGTH',
                    'PIN zunify must be at least 4 characters'
                )
            )
            .max(
                4,
                getValueIntl(
                    'ZUNIFY_PIN_MAX_LENGTH',
                    'PIN zunify must be at most 4 characters'
                )
            )
            .required(getValueIntl('ZUNIFY_PIN_REQUIRED', 'PIN is required')),
        paymentMethod: yupPaymentMethod
    })

    const memoizedContextObject = useMemo(
        () => ({
            state,
            cardDataState,
            handleInputFocus,
            handleInputChange,
            schemaCheckoutCardForm,

            clientInfoChargeLinkState,
            handleInputFocusClientInfo,
            handleInputChangeClientInfo,
            schemaClientInformationChargeLinkForm,

            zunifyState,
            handleInputFocusZunify,
            handleInputChangeZunify,
            schemaZunifyCheckoutForm,

            handleOrderChange,

            payOrder,
            payOrderZunify,
            processingOrder,

            showNumberOfPayments,
            isInvoice,
            af_pa_setup_3DS,
            af_pa_enrollment_3DS,
            af_pa_validate_3DS,
            setProccesingStatus
        }),
        [
            state,
            cardDataState,
            handleInputFocus,
            handleInputChange,
            schemaCheckoutCardForm,
            clientInfoChargeLinkState,
            handleInputFocusClientInfo,
            handleInputChangeClientInfo,
            schemaClientInformationChargeLinkForm,
            zunifyState,
            handleInputFocusZunify,
            handleInputChangeZunify,
            schemaZunifyCheckoutForm,
            handleOrderChange,
            payOrder,
            payOrderZunify,
            processingOrder,
            showNumberOfPayments,
            isInvoice,
            af_pa_setup_3DS,
            af_pa_enrollment_3DS,
            af_pa_validate_3DS,
            setProccesingStatus
        ]
    )

    return (
        <CheckoutFormContext.Provider value={memoizedContextObject}>
            {props.children}
        </CheckoutFormContext.Provider>
    )
}

CheckoutFormContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    initialOrder: PropTypes.object
}
