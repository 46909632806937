import React, { createContext, useEffect, useState, useCallback } from 'react'

import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import useForceUpdate from 'use-force-update'

import addressDataResponse from './address-data.json'
import {
    getServiceConfigAPI,
    getMerchantCredentials,
    generateToken,
    getSessionView,
    getInvoiceLinkShortenedData,
    getMerchantData
} from './../api/merchant'

import esES from './../locales/es_ES.json'
import enUS from './../locales/en_US.json'
import { CONSTANTS } from '../config/constants'
import { CONFIG } from '../config'
import { verifyToken } from '../utils'
import { createPublicKey } from '../encryption'

const USA_STATES = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
}

const LOCALE_DATA = {
    'en-US': enUS,
    'es-ES': esES
}

intl.init({
    currentLocale: localStorage.getItem('seleted_locale') || 'es-ES',
    locales: LOCALE_DATA
})

const order = {
    session: undefined,
    privateKey: undefined,
    token: undefined,
    merchant: {
        id: undefined,
        name: undefined,
        identification: undefined,
        email: undefined,
        webhook: [],
        enabled: undefined,
        callback: null,
        company_erl_id: undefined,
        bank_entity: undefined,
        bank_entity_type: undefined,
        private_key: undefined,
        marketplace: undefined,
        locale: undefined,
        terms_n_conditions: undefined,
        configs: {
            notifyMerchantError: undefined,
            notifyClient: undefined,
            amountLimit: undefined,
            transactionTimeLimit: undefined,
            fixedSettlementAmount: undefined,
            blacklistEmail: undefined,
            notifyMerchant: undefined,
            allowWhitelist: undefined,
            allowRemoveBlacklistCard: undefined
        },
        logo: undefined,
        share_cards: undefined,
        additional_data: {}
    },
    secret: undefined,
    merchantId: undefined,
    terminal: undefined,
    numberOfPayments: undefined,
    amount: undefined,
    currency: undefined,
    description: undefined,
    orderReference: undefined,
    additional: undefined,
    shopper_ip: undefined
}

export const AppContext = createContext({
    currentLocale: 'en-US',
    initIntlDone: false,
    onLocalChangeIntl: undefined,
    loadCheckoutSessionData: undefined,
    loadCheckoutLinkShortenedData: undefined,
    countries: [],
    usaStates: USA_STATES,
    addressData: null,
    globalState: {
        error: undefined,
        credentials: { merchantId: undefined, secret: undefined },
        loadingInitialData: 'loading',
        config: {
            acceptedCards: ['vs', 'mc'],
            hiddenFields: {
                CVC: false,
                CardHolder: false,
                expirationDate: false
            },
            separateCardHolderName: true,
            socialLogin: false,
            tokenize: ''
        },
        order,
        merchantData: undefined
    },
    antiFraudConfig: {
        kount: {
            enabled: false
        },
        threeds: {
            enabled: false
        }
    }
})

export const AppContextProvider = (props) => {
    const [state, setState] = useState({
        currentLocale: localStorage.getItem('seleted_locale') || 'es-ES',
        countries: addressDataResponse.result.countries,
        addressData: addressDataResponse.result.addressData,
        usaStates: USA_STATES
    })

    const { countries, addressData, usaStates } = state
    const forceUpdate = useForceUpdate()
    const [initIntlDone, setInitIntlDone] = useState(false)

    const [antiFraudConfig, setAntiFraudConfig] = useState({
        kount: {
            enabled: false
        },
        threeds: {
            enabled: false
        }
    })

    const [globalState, setGlobalState] = useState(
        props?.initialState || {
            error: undefined,
            loadingInitialData: CONSTANTS.GLOBAL_STATE.LOADING,
            credentials: { merchantId: undefined, secret: undefined },
            config: {
                acceptedCards: ['vs', 'mc'],
                hiddenFields: {
                    CVC: false,
                    CardHolder: false,
                    expirationDate: false
                },
                separateCardHolderName: true,
                socialLogin: false,
                tokenize: ''
            },
            order,
            merchantData: undefined
        }
    )

    const initializeIntl = () => {
        setCurrentLocale(state.currentLocale)
        setInitIntlDone(true)
    }

    useEffect(() => {
        initializeIntl()
        setupDataCollectorScript()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setCurrentLocale = (currentLocale) => {
        intl.init({
            currentLocale,
            locales: LOCALE_DATA
        })
    }

    const onLocalChangeIntl = useCallback(
        (locale) => {
            localStorage.setItem('seleted_locale', locale)
            setCurrentLocale(locale)
            setState({ ...state, currentLocale: locale })
            forceUpdate()
        },
        [state, forceUpdate]
    )

    const setupKountScript = (kountSSID) => {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `${CONFIG.KOUNT_URL}/collect/sdk?m=${CONFIG.KOUNT_MERCHANT}&s=${kountSSID}`
        script.async = true
        script.addEventListener('load', () => {
            let client = new window.ka.ClientSDK()
            client.autoLoadEvents()
        })
        document.head.appendChild(script)
    }

    const setupDataCollectorScript = () => {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = CONFIG.DATA_COLLECTOR_URL
        script.async = true
        document.head.appendChild(script)
    }

    const setupCss = (merchant_id) => {
        const link = document.createElement('link')

        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = `${CONFIG.CSS_BUCKET}/${merchant_id}.css`

        document.head.appendChild(link)
    }

    const validateAntifraud = useCallback(
        (session, merchantData = {}, order = {}) => {
            const { additional_data = {} } = merchantData
            const { antifraud = {} } = additional_data
            const { kount = {} } = antifraud

            const { merchant = {} } = order
            const { fraud = {} } = merchant

            let vKount = kount?.enabled || fraud?.antiFraudKount
            let v3DS = fraud?.antiFraud3DSecure
            setAntiFraudConfig({
                ...antiFraudConfig,
                kount: {
                    enabled: vKount
                },
                threeds: {
                    enabled: v3DS
                }
            })

            if (vKount) {
                setupKountScript(session.replace(/-/g, ''))
            }
        },
        [antiFraudConfig]
    )

    const mapConfig = useCallback((config) => {
        return {
            acceptedCards: config?.acceptedCards ?? ['vs', 'mc'],
            hiddenFields: {
                CVC: config?.hiddenFields?.CVC === true,
                CardHolder: config?.hiddenFields?.CardHolder === true,
                expirationDate: config?.hiddenFields?.expirationDate === true
            },
            separateCardHolderName: config?.separateCardHolderName ?? true,
            socialLogin: config?.socialLogin ?? false,
            tokenize: config?.tokenize ?? ''
        }
    }, [])

    const loadCheckoutSessionData = useCallback(
        async (session) => {
            setGlobalState({
                ...globalState,
                loadingInitialData: CONSTANTS.GLOBAL_STATE.LOADING
            })

            let credentials = null
            let config = null
            let merchantData = null
            try {
                credentials = await getMerchantCredentials(session)
                merchantData = await getMerchantData(
                    credentials.merchantId,
                    credentials.secret
                )

                if (!merchantData) {
                    credentials = {
                        merchantId: CONFIG.GP_MERCHANT_ID,
                        secret: CONFIG.GP_SECRET
                    }
                    merchantData = await getMerchantData(
                        credentials.merchantId,
                        credentials.secret
                    )
                }

                config = await getServiceConfigAPI(credentials.merchantId)
                await generateToken(credentials.merchantId, credentials.secret)
            } catch (error) {
                setGlobalState({
                    ...globalState,
                    error: CONSTANTS.ERRORS.PURCHASE_NOT_FOUND,
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED
                })
                return
            }

            try {
                const _order = await getSessionView(
                    session,
                    credentials.merchantId,
                    credentials.secret
                )

                if (merchantData && merchantData.enabled === 'disabled') {
                    setGlobalState({
                        ...globalState,
                        loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED,
                        error: CONSTANTS.ERRORS.DISABLED_MERCHANT
                    })
                    return
                }

                validateAntifraud(session, merchantData, _order)

                setupCss(credentials.merchantId?.replace(/-/g, ''))
                setGlobalState({
                    ...globalState,
                    credentials,
                    config: mapConfig(config || globalState.config),
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED,
                    order: _order,
                    merchantData
                })
            } catch (error) {
                setGlobalState({
                    ...globalState,
                    error: CONSTANTS.ERRORS.WRONG_DATA,
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED
                })
            }
        },
        [globalState, validateAntifraud, setGlobalState, mapConfig]
    )

    const loadCheckoutLinkShortenedData = useCallback(
        async (shortened) => {
            setGlobalState({
                ...globalState,
                loadingInitialData: CONSTANTS.GLOBAL_STATE.LOADING
            })

            let credentials = null
            let config = null
            let linkData = null
            let merchantData = null

            try {
                credentials = await getMerchantCredentials(
                    shortened,
                    'shortened'
                )
                merchantData = await getMerchantData(
                    credentials.merchantId,
                    credentials.secret
                )

                config = await getServiceConfigAPI(credentials.merchantId)
                await generateToken(credentials.merchantId, credentials.secret)
                linkData = await getInvoiceLinkShortenedData(
                    shortened,
                    credentials.merchantId,
                    credentials.secret
                )
            } catch (error) {
                setGlobalState({
                    ...globalState,
                    error: CONSTANTS.ERRORS.INVALID_LINK,
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED
                })
                return
            }

            let decoded = null
            try {
                decoded = await verifyToken(
                    linkData.access_token,
                    createPublicKey(linkData.private_key)
                )
            } catch (error) {
                setGlobalState({
                    ...globalState,
                    error: CONSTANTS.ERRORS.INVALID_LINK,
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED
                })
                return
            }

            try {
                const _order = await getSessionView(
                    decoded.session,
                    credentials.merchantId,
                    credentials.secret
                )
                if (merchantData && merchantData.enabled === 'disabled') {
                    setGlobalState({
                        ...globalState,
                        loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED,
                        error: CONSTANTS.ERRORS.DISABLED_MERCHANT
                    })
                    return
                }

                validateAntifraud(decoded.session, merchantData, _order)
                setupCss(credentials.merchantId?.replace(/-/g, ''))
                setGlobalState({
                    ...globalState,
                    credentials,
                    config: mapConfig(config || globalState.config),
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED,
                    order: _order
                })
            } catch (error) {
                setGlobalState({
                    ...globalState,
                    error: CONSTANTS.ERRORS.WRONG_DATA,
                    loadingInitialData: CONSTANTS.GLOBAL_STATE.FINISHED
                })
                return
            }
        },
        [globalState, setGlobalState, validateAntifraud, mapConfig]
    )

    const appContextObject = React.useMemo(
        () => ({
            currentLocale: state.currentLocale,
            initIntlDone,
            onLocalChangeIntl,
            countries,
            addressData,
            globalState,
            antiFraudConfig,
            usaStates,
            loadCheckoutSessionData,
            loadCheckoutLinkShortenedData
        }),
        [
            state.currentLocale,
            initIntlDone,
            onLocalChangeIntl,
            countries,
            addressData,
            globalState,
            antiFraudConfig,
            usaStates,
            loadCheckoutSessionData,
            loadCheckoutLinkShortenedData
        ]
    )

    return (
        <AppContext.Provider value={appContextObject}>
            {props.children}
        </AppContext.Provider>
    )
}

AppContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    initialState: PropTypes.object
}
